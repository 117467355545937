var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'name',
    'activity',
    'instructor',
    'location',
    'capacity',
    'note' ],"formats":{
    capacity: 'UpDown',
  },"itemsPerPage":"10","searchOnStart":true,"table":"groups"}})}
var staticRenderFns = []

export { render, staticRenderFns }